<template>
    <section class="community-management">
        <div class="form-search-container bg-white text-center">
            <el-button type="primary" @click="handleAddEditCommunity('add', { type: 0 })" size="small"
                       icon="el-icon-plus" class="add">
                新增小区
            </el-button>
            <el-form :model="formSearch" inline size="small">
                <el-form-item>
                    <el-input v-model="formSearch.keyword" placeholder="请输入小区名称搜索" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                    <el-button type="primary" size="small" icon="el-icon-download" @click="communityExport">导出
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="table-container bg-white">
            <r-e-table ref="communityTableRef" :dataRequest="getCommunityTableData" :afterDataReq="afterDataReq"
                       :columns="tableColumn" :query="formSearch" :height="840" :show-pagination="false">
                <el-table-column slot="toolbar" label="操作" width="200">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <!-- <span  class="table-btn" @click="handleAddEditCommunity('edit', row)">修改</span> -->
                            <span v-if="row.addName === '楼幢'" class="table-btn" @click="handleAddEditUnitPrice(row)">编辑单价</span>
                            <span v-if="row.children" class="table-btn"
                                  @click="handleAddEditCommunity('add', row)">新增{{ row.addName }}</span>
                            <span class="table-btn" @click="handleAddEditCommunity('edit', row)">
                                修改{{row.addNames }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>

        <!--   添加、修改小区弹框       -->
        <layer-add-edit-community class="layer-add-edit-community" ref="communityRef"/>
        <!--   添加、修改楼幢或单元弹框   -->
        <layer-add-edit-building class="layer-add-edit-building" ref="buildingRef"/>
        <!--   编辑单价   -->
        <layer-add-edit-unit-price ref="layerAddEditUnitPrice" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
import {tableColumn} from "@/views/rental-management/community-management/data";
import {layerAddEditCommunity, layerAddEditBuilding,} from "@/views/rental-management/community-management/components";
import {communityAllExport} from "@/api/community-management";
import {timeFormat} from "@custom/index";
import {downloadByData} from "@/utils/hooks/download";

export default {
    name: "community-management",
    data() {
        return {
            tableColumn,
            formSearch: {
                keyword: null,
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    components: {
        layerAddEditCommunity,
        layerAddEditBuilding,
        layerAddEditUnitPrice: () => import("./components/layer-add-edit-unit-price"),
    },
    created() {
        let {params: {data},} = this.$route;
        if (data) {
            const {name} = data;
            if (name) this.formSearch.keyword = name;
        }
    },
    methods: {
        // 获取小区+楼栋+单元列表
        getCommunityTableData(params) {
            // 获取小区+楼栋+单元列表
            return this.$store.dispatch("community/GetCommunityListActions", params);
        },
        afterDataReq(e) {
            // 新增按钮修改提示名称
            e.forEach((v) => {
                v.addName = "楼幢";
                v.addNames = "小区";
                v.children.forEach((v) => {
                    v.addName = "单元";
                    v.addNames = "楼幢";
                    v.children.forEach((v) => {
                        v.addNames = "单元";
                    });
                });
            });
        },
        // 搜索
        handleSearch() {
            this.$refs.communityTableRef.getTableData();
        },
        // 重置
        handleReset() {
            this.formSearch.keyword = null;
            this.handleSearch();
        },

        // 新增、编辑打开小区、楼幢或单元弹窗
        handleAddEditCommunity(addEdit, row) {
          console.log(row)
            const {type = 0} = row;
            // 新增、编辑打开小区弹窗
            if ((type === 0 && addEdit === "add") || (type === 1 && addEdit === "edit")) {
                this.$refs.communityRef.layerAddEditCommunityInit(addEdit, row);
            } else {
                // 新增、编辑打开楼幢或单元弹窗
                this.$refs.buildingRef.layerAddEditBuildingInit(addEdit, row);
            }
        },

        //打开编辑单价弹窗
        handleAddEditUnitPrice(data) {
            this.$refs["layerAddEditUnitPrice"].openDialog(data);
        },

        // 导出数据
        communityExport() {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            communityAllExport().then((res) => {
                downloadByData({data: res, filename: `小区管理${timeFormat(new Date(), "yyyy-MM-dd-HH-mm-ss")}.xlsx`});
            }).finally(() => loading.close());
        },
    },
};
</script>

<style lang="scss" scoped>
.community-management {
    padding: 15px;

    .form-search-container {
        padding-top: VH(20px);
        margin-bottom: VH(15px);

        /deep/ .el-form {
            .el-form-item .el-input__inner {
                width: VW(250px);
            }
        }
    }

    .add {
        position: absolute;
        left: VW(40px);
    }
}
</style>
